<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  data() {
    return {
      
    }
  },
}
</script>
<style lang="less">
  #app{
    // background-color:#043559;
    height: 100%;
    width: 100%;
    position: fixed;
  }
  .el-popover{
    background-image: linear-gradient(-20deg, #08f4d4 40%, #51f406 85%)!important;
    font-size: 20px!important;
    font-weight: 700!important;
    color: #fff!important;
    img{
      width: 200px!important;
      height: 200px!important;
    }
  }
</style>

import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("@/views/home"),
    redirect: "/zhuye",
    children: [
      {
        path: "/zhuye",
        name: "zhuye",
        component: () => import("@/views/zhuye/index.vue"),
      },
      {
        path: "/jianli",
        name: "jianli",
        component: () => import("@/views/jianli/index.vue"),
      },
      {
        path: "/zuopinji",
        name: "zuopinji",
        component: () => import("@/views/zuopinji/index.vue"),
      },
      {
        path: "/xindebiji",
        name: "xindebiji",
        component: () => import("@/views/xindebiji/index.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  routes,
});

export default router;